import React, { Component } from 'react';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';
import PageNotFound from '../common/lib/PageNotFound';
import { MultiLang } from '../config';

interface Props extends RouteComponentProps {
    lang: MultiLang;
}

class CreditsXoopsPathRedirect extends Component<Props> {

    getRedirectUrl(): string {
        const { location } = this.props;
        const name = 'credits';
        const pathname = location.pathname || '';
        const query = new URLSearchParams(location.search);
        const search = new RegExp(`^/modules/${name}(?:/+(.*))?$`);
        const matches = pathname.match(search);
        if (matches === null) {
            return '';
        }
        const path = matches[1] || '';
        switch (path) {
            case '':
            case 'index.php': {
                const id = query.get('id');
                if (id !== null) {
                    if (id.match(/^\d+$/) !== null) {
                        return '/' + name + '/' + id;
                    }
                    return '';
                }
                return '/' + name;

            }
            case 'aboutus.php': {
                return '/' + name;
            }
        }
        return '';
    }

    render() {
        const { lang } = this.props;
        const url = this.getRedirectUrl();
        if (url === '') {
            return <PageNotFound lang={lang} />;
        }
        return <Redirect to={url} />;
    }
}

export default withRouter(CreditsXoopsPathRedirect);
