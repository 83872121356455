import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import PageNotFound from '../common/lib/PageNotFound';
import { MultiLang } from '../config';
import Functions from '../functions';
import PicoUtils, { PicoCategoryData, PicoContentData } from './lib/PicoUtils';

interface Props {
    lang: MultiLang;
    name: string;
    catId: number;
}

const PicoCategory = (props: Props) => {
    const { lang, name, catId } = props;
    const pico = PicoUtils.getModule(name);
    if (pico === null) {
        return <PageNotFound lang={lang} />;
    }
    const category = PicoUtils.getCategory(name, catId);
    if (category === null) {
        return <PageNotFound lang={lang} />;
    }
    const parentCategories = PicoUtils.getParentCategories(name, category.pid);
    const subCategories = PicoUtils.getSubCategories(name, catId);
    const contents = PicoUtils.getCategoryContents(name, catId);
    return (
        <div className="pico_container">
            <Helmet>
                <title>{Functions.mlang(category.title, lang)} - {Functions.mlang(pico.name, lang)} - {Functions.siteTitle(lang)}</title>
            </Helmet>
            {pico.show_breadcrumbs !== 0 && (
                <div className="pico_breadcrumbs">
                    {parentCategories.map((parentCategory: PicoCategoryData, idx: number) => {
                        const link = '/' + name + '/' + parentCategory.link;
                        return (
                            <Fragment key={parentCategory.id}>
                                {idx > 0 && <>&nbsp;&gt;&nbsp;</>}
                                <Link key={parentCategory.id} to={link}>{Functions.mlang(parentCategory.title, lang)}</Link>
                            </Fragment>
                        );
                    })}
                    {parentCategories.length > 0 && <>&nbsp;&gt;&nbsp;</>}
                    {Functions.mlang(category.title, lang)}
                </div>
            )}
            {catId === 0 && pico.message !== '' && (
                <p>{Functions.mlang(pico.message, lang)}</p>
            )}
            <h1>{Functions.mlang(category.title, lang)}</h1>
            {category.desc !== '' && (
                <p>{Functions.mlang(category.desc, lang)}</p>
            )}
            {subCategories.length > 0 && (
                <>
                    <h2>{Functions.mlang('[en]Subcageroies[/en][ja]サブカテゴリー[/ja]', lang)}</h2>
                    {subCategories.map((subCategory: PicoCategoryData) => {
                        const url = '/' + name + '/' + subCategory.link;
                        return (
                            <dl key={subCategory.id} className="pico_subcategory">
                                <dt><Link to={url}>{Functions.mlang(subCategory.title, lang)}</Link></dt>
                                <dd>{Functions.mlang(subCategory.desc, lang)}</dd>
                            </dl>
                        );
                    })}
                </>
            )}
            {contents.length > 0 && (
                <>
                    <h2>{Functions.mlang('[en]Contents[/en][ja]コンテンツ[/ja]', lang)}</h2>
                    <ul className="pico_list_contents">
                        {contents.map((content: PicoContentData) => {
                            const url = '/' + name + '/' + content.link;
                            return <li key={content.id}><Link to={url}>{Functions.mlang(content.title, lang)}</Link></li>;
                        })}
                    </ul>
                </>
            )}
        </div>
    );
};

export default PicoCategory;