import React from 'react';
import { Helmet } from 'react-helmet';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import PageNotFound from '../common/lib/PageNotFound';
import { MultiLang } from '../config';
import Functions from '../functions';
import CreditsAboutUs from './CreditsAboutUs';
import CreditsPage from './CreditsPage';
import CreditsUtils from './lib/CreditsUtils';

interface Props {
    lang: MultiLang;
}

const Credits = (props: Props) => {
    const { lang } = props;
    return (
        <>
            <Helmet>
                <title>{CreditsUtils.getTitle(lang)} - {Functions.siteTitle(lang)}</title>
            </Helmet>
            <Switch>
                <Route exact path="/credits" render={() => <CreditsAboutUs {...props} />} />
                <Route exact path="/credits/:id" render={(props: RouteComponentProps<{ id: string }>) => <CreditsPage lang={lang} {...props} />} />
                <Route component={PageNotFound} />
            </Switch>
        </>
    );
}

export default Credits;
