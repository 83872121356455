import React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { MultiLang } from '../config';
import CreditsUtils from '../credits/lib/CreditsUtils';
import Functions from '../functions';
import imageHeaderLeft from './assets/theme/images/header_left.gif';
import imageHeaderRight from './assets/theme/images/header_right.gif';
import iconContact from './assets/theme/images/icon_contact.gif';
import iconHelp from './assets/theme/images/icon_help.gif';
import iconHome from './assets/theme/images/icon_home.gif';
import LangFlag from './lib/LangFlag';

interface Props extends RouteComponentProps {
    lang: MultiLang;
}

const Header = (props: Props) => {
    const { lang } = props;
    const onClickMap = (e: React.MouseEvent<HTMLAreaElement>, url: string) => {
        e.preventDefault();
        if (url.match(/^https?:\/\//)) {
            window.open(url, '_blank');
            return;
        }
        props.history.push(url);
    }
    const urlHome = '/';
    const urlHelp = (lang === 'en' ? 'https://xoonips.osdn.jp/manuals/usersman-en/index.html' : '/manual_ja/manual.html');
    const urlContact = CreditsUtils.getIndexUrl();
    const titleHome = 'Home';
    const titleHelp = 'Help';
    const titleContact = 'Contact us';
    return (
        <header className="header_bar clearfix">
            <div className="logo">
                <img src={imageHeaderLeft} alt={Functions.siteTitle(lang)} useMap="#logo" />
            </div>
            <div className="menu_bar clearfix">
                <div className="menu_img">
                    <img src={iconHome} alt={titleHome} useMap="#home" /><br />
                    <Link to={urlHome} title={titleHome}>{titleHome}</Link>
                </div>
                <div className="menu_img">
                    <img src={iconHelp} alt={titleHelp} useMap="#help" /><br />
                    {lang === 'en' ?
                        <a href={urlHelp} title={titleHelp} target="_blank" rel="noopener noreferrer">{titleHelp}</a> :
                        <Link to={urlHelp} title={titleHelp}>{titleHelp}</Link>
                    }
                </div>
                <div className="menu_img">
                    <img src={iconContact} alt={titleContact} useMap="#contact" /><br />
                    <Link to={urlContact} title={titleContact}>{titleContact}</Link>
                </div>
                <div className="menu_img">
                    <img src={imageHeaderRight} alt="eye" /><br />
                    <LangFlag lang="en" /> <LangFlag lang="ja" />
                </div>
            </div>
            <map name="logo"><area shape="rect" coords="13,20,191,54" title={titleHome} alt={titleHome} href={urlHome} onClick={(e) => { onClickMap(e, urlHome); }} /></map>
            <map name="home"><area shape="circle" coords="37,63,17" title={titleHome} alt={titleHome} href={urlHome} onClick={(e) => { onClickMap(e, urlHome); }} /></map>
            <map name="help"><area shape="circle" coords="37,63,17" title={titleHelp} alt={titleHelp} href={urlHelp} onClick={(e) => { onClickMap(e, urlHelp); }} /></map>
            <map name="contact"><area shape="circle" coords="37,63,17" title={titleContact} alt={titleContact} href={urlContact} onClick={(e) => { onClickMap(e, urlContact); }} /></map>
        </header>
    );
}

export default withRouter(Header);
