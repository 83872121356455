import React from 'react';
import XoopsCode from '../common/lib/XoopsCode';
import { MultiLang } from '../config';
import Functions from '../functions';
import CreditsUtil from './lib/CreditsUtils';
import NoticeSiteHasBeenArchived from '../common/lib/NoticeSiteHasBeenArchived';

interface Props {
    lang: MultiLang;
}

const CreditsAboutUs = (props: Props) => {
    const { lang } = props;
    const title = Functions.mlang(CreditsUtil.getAboutUsTitle(), lang);
    return (
        <>
            <h3>{title}</h3>
            <NoticeSiteHasBeenArchived lang={lang} />
            <XoopsCode lang={lang} text={CreditsUtil.getAboutUsContent()} dohtml={true} />
        </>
    );
}

export default CreditsAboutUs;
