import React from 'react';
import PageNotFound from '../common/lib/PageNotFound';
import { MultiLang } from '../config';
import PicoUtils from './lib/PicoUtils';
import PicoCategory from './PicoCategory';
import PicoContent from './PicoContent';
import PicoMenu from './PicoMenu';

interface Props {
    lang: MultiLang;
    name: string;
}

const PicoIndex = (props: Props) => {
    const { lang, name } = props;
    const pico = PicoUtils.getModule(name);
    if (pico === null) {
        return <PageNotFound lang={lang} />;
    }
    if (pico.show_menuinmoduletop !== 0) {
        return <PicoMenu lang={lang} name={name} />
    }
    if (pico.show_listasindex !== 0) {
        return <PicoCategory lang={lang} name={name} catId={0} />
    }
    const content = PicoUtils.getFirstContent(name, 0);
    if (content === null) {
        return <PageNotFound lang={lang} />;
    }
    return <PicoContent lang={lang} name={name} contentId={content.id} />;

}

export default PicoIndex;
