import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { MultiLang } from '../../config';
import mlangEnglish from '../assets/images/mlang_english.gif';
import mlangJapanese from '../assets/images/mlang_japanese.gif';

interface Props extends RouteComponentProps {
    lang: MultiLang;
}

const styleLink = {
    fontSize: '8px',
};
const styleImage = {
    verticalAlign: 'middle',
    border: '1px solid #000',
};
const langResources = {
    en: { image: mlangEnglish, title: 'English' },
    ja: { image: mlangJapanese, title: 'Japanese' },
};

const LangFlag = (props: Props) => {
    const { lang } = props;
    const params = new URLSearchParams(props.location.search);
    const flagLang = lang === 'en' ? 'ja' : 'en';
    params.set('ml_lang', flagLang);
    const url = props.location.pathname + '?' + params.toString();
    return (
        <Link style={styleLink} to={url}>
            <img style={styleImage} src={langResources[flagLang].image} alt={langResources[flagLang].title} title={langResources[flagLang].title}/>
        </Link>
    );
}


export default withRouter(LangFlag);
