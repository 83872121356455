import React from 'react';
import NoticeSiteHasBeenArchived from '../../common/lib/NoticeSiteHasBeenArchived';
import { MultiLang } from '../../config';

interface Props {
    lang: MultiLang;
}

const Welcome = (props: Props) => {
    const { lang } = props;
    const announce = {
        'en':
            <p>
                Visiome Platform is a web-based database system with a variety of digital research resources in Vision Science.
                This platform provides several types of contents, such as Experimental data, Mathematical model, Illusion stimulus, Analytical tools. Archive files may be written in any formats and may include explanatory figures, program sources, readme files, and other related files.
                The readme file describes the purpose and usage of the archive file. This platform provides a data sharing framework, and thus, users can improve the reproducibility of simulations.
                Users can also browse the platform contents via branch sites (<a href="http://www.psy.ritsumei.ac.jp/~akitaoka/cataloge.html" target="_blank" rel="noopener noreferrer">A catalogue of illusions</a>, <a href="http://visitope.org/visualstimuluslist.html?lang=en" target="_blank" rel="noopener noreferrer">Visitope</a>, <a href="http://www.youtube.com/user/VisiomeChannel?feature=watch" target="_blank" rel="noopener noreferrer">Visiome Channel</a>), which introduce user friendly view of items such as basic images and original artworks of visual illusions with high resolution.
                The items in Visiome Platform are useful not only for reproducing the published results, but also for advancing and expanding the research in Vision Science.
            </p>,
        'ja':
            <p>
                Visiome Platformは、視覚研究に関わるあらゆる情報を収集、公開、補完することを目的とするWebベースのデジタルアーカイブサイトです。
                アーカイブの対象は、実験データ、数理モデル、錯視図形、視覚刺激生成プログラム、解析用ツールなどです。これらのコンテンツは対応するアイテムに分類され、解説や使用法と共に登録されています。
                ユーザは登録者の設定した利用規定に従ってコンテンツをダウンロードし、利用することができます。
                数理モデルでは、論文等に掲載された図を再現するのに必要なモデル記述やパラメータ、シミュレーションプログラムの使い方などが記載されており、シミュレーション実験を容易に追試することができます。
                錯視図形や視覚刺激生成プログラムなどでは、別サイト（<a href="http://www.psy.ritsumei.ac.jp/~akitaoka/catalog.html" target="_blank" rel="noopener noreferrer">錯視カタログ</a>、<a href="http://visitope.org/visualstimuluslist.html?lang=jp" target="_blank" rel="noopener noreferrer">Visitope</a>、<a href="http://www.youtube.com/user/VisiomeChannel?feature=watch" target="_blank" rel="noopener noreferrer">Visiome Channel</a>）でプレビューを見てコンテンツを確認することもでき、高解像度ファイルをダウンロードする時に本プラットフォームへ誘導する仕組みが備えられています。
            </p>,
    }
    return (
        <>
            <NoticeSiteHasBeenArchived lang={lang} />
            {announce[props.lang]}
        </>
    );
}

export default Welcome;