import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Config, { MultiLang } from '../config';
import Credits from '../credits/Credits';
import CreditsXoopsPathRedirect from '../credits/CreditsXoopsPathRedirect';
import Database from '../database/Database';
import DatabaseXoopsPathRedirect from '../database/DatabaseXoopsPathRedirect';
import Pico from '../pico/Pico';
import PicoXoopsPathRedirect from '../pico/PicoXoopsPathRedirect';
import XoopsPathRedirect from './XoopsPathRedirect';

interface Props {
    lang: MultiLang;
}

const MainContent = (props: Props) => {
    const { lang } = props;
    return (
        <div className="mainContent">
            <Switch>
                <Route exact path="/database/list/6940" render={() => <Redirect to="/primface" />} />
                <Route path="/database" render={() => <Database {...props} />} />
                <Route path="/credits" render={() => <Credits lang={lang} />} />
                {Config.PICO_MODULES.map((name) =>
                    <Route key={name} path={'/' + name} render={() => <Pico lang={lang} name={name} />} />
                )}
                <Route path="/modules/xoonips" render={() => <DatabaseXoopsPathRedirect lang={lang} />} />
                <Route path="/modules/credits" render={() => <CreditsXoopsPathRedirect lang={lang} />} />
                {Config.PICO_MODULES.map((name) =>
                    <Route key={name} path={'/modules/' + name} render={() => <PicoXoopsPathRedirect lang={lang} name={name} />} />
                )}
                <Route component={XoopsPathRedirect} />
            </Switch>
        </div>
    );
}

export default MainContent;