import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { MultiLang } from '../../config';

interface Props extends RouteComponentProps {
    lang: MultiLang;
}

const SelectLang = (props: Props) => {
    const { lang, history } = props;
    const langs: MultiLang[] = ['en', 'ja'];
    const titles = {
        en: 'English',
        ja: 'Japanese',
    };
    return (
        <div>
            <select value={lang} onChange={(e) => {
                const xlang = e.target.value;
                const params = new URLSearchParams(history.location.search);
                params.set('ml_lang', xlang);
                const url = history.location.pathname + '?' + params.toString();
                history.push(url);
            }}>
                {langs.map((xlang) => {
                    return <option key={xlang} value={xlang}>{titles[xlang]}</option>;
                })}
            </select>

        </div>
    );
}

export default withRouter(SelectLang);