import axios from 'axios';
import loki from 'lokijs';
import configJson from '../assets/config.json';

export interface PicoModuleData {
    name: string;
    dirname: string;
    message: string;
    show_menuinmoduletop: number;
    show_listasindex: number;
    show_breadcrumbs: number;
    show_pagenavi: number;
}

export interface PicoCategoryData {
    id: number;
    title: string;
    desc: string;
    pid: number;
    weight: number;
    link: string;
}

export interface PicoContentData {
    id: number;
    title: string;
    cat_id: number;
    weight: number;
    link: string;
}

interface PicoConfigData {
    module: PicoModuleData;
    categories: PicoCategoryData[];
    contents: PicoContentData[];
}

interface PicoData {
    module: PicoModuleData;
    categories: Collection<PicoCategoryData>;
    contents: Collection<PicoContentData>;
}

export interface PicoPageData {
    id: number;
    title: string;
    content: string;
}

export const PICO_CATEGORY_ID_ROOT = 65535;
export type GetPageCallback = (page: PicoPageData | null) => void;

class PicoUtils {

    private database: loki;
    private modules: Map<string, PicoData>;

    constructor(json: PicoConfigData[]) {
        this.database = new loki('pico');
        this.modules = new Map<string, PicoData>();
        json.forEach((data) => {
            const name = data.module.dirname;
            const pico = {
                module: data.module,
                categories: this.database.addCollection<PicoCategoryData>(name + '_categories'),
                contents: this.database.addCollection<PicoContentData>(name + '_contents'),
            };
            data.categories.forEach(category => {
                pico.categories.insert(category);
            });
            data.contents.forEach(content => {
                pico.contents.insert(content);
            });
            this.modules.set(name, pico);
        })
    }

    getModule(name: string): PicoModuleData | null {
        const pico = this.modules.get(name);
        if (typeof pico === 'undefined') {
            return null;
        }
        return pico.module;
    }

    getCategoryByPath(name: string, link: string): PicoCategoryData | null {
        const pico = this.modules.get(name);
        if (typeof pico === 'undefined') {
            return null;
        }
        const content = pico.categories.findOne({ link: link });
        return content;
    }

    getCategory(name: string, catId: number): PicoCategoryData | null {
        const pico = this.modules.get(name);
        if (typeof pico === 'undefined') {
            return null;
        }
        const category = pico.categories.findOne({ id: catId });
        return category;
    }

    getParentCategories(name: string, catId: number): PicoCategoryData[] {
        const categories: PicoCategoryData[] = [];
        const pico = this.modules.get(name);
        if (typeof pico === 'undefined') {
            return categories;
        }
        while (catId !== PICO_CATEGORY_ID_ROOT) {
            const category = pico.categories.findOne({ id: catId });
            if (category === null) {
                break;
            }
            categories.unshift(category);
            catId = category.pid;
        }
        return categories;
    }

    getSubCategories(name: string, catId: number): PicoCategoryData[] {
        const pico = this.modules.get(name);
        if (typeof pico === 'undefined') {
            return [];
        }
        return pico.categories.chain().find({ pid: catId }).simplesort('weight').data();
    }

    getCategoryContents(name: string, catId: number): PicoContentData[] {
        const pico = this.modules.get(name);
        if (typeof pico === 'undefined') {
            return [];
        }
        return pico.contents.chain().find({ cat_id: catId }).simplesort('weight').data();
    }

    getContentCategery(name: string, contentId: number): PicoCategoryData[] {
        const pico = this.modules.get(name);
        if (typeof pico === 'undefined') {
            return [];
        }
        const content = pico.contents.findOne({ id: contentId });
        if (content === null) {
            return [];
        }
        return this.getParentCategories(name, content.cat_id);
    }

    getFirstContent(name: string, catId: number): PicoContentData | null {
        const pico = this.modules.get(name);
        if (typeof pico === 'undefined') {
            return null;
        }
        const contents = pico.contents.chain().find({ cat_id: catId }).simplesort('weight').limit(1).data();
        if (contents.length !== 1) {
            return null;
        }
        return contents[0];
    }

    getContentByPath(name: string, link: string): PicoContentData | null {
        const pico = this.modules.get(name);
        if (typeof pico === 'undefined') {
            return null;
        }
        const content = pico.contents.findOne({ link: link });
        return content as PicoContentData | null;
    }

    getPage(name: string, contentId: number, callback: GetPageCallback): void {
        axios.get('/' + name + '/' + contentId + '.json').then((response) => {
            const page = response.data as PicoPageData;
            callback(page);
        }).catch(e => {
            callback(null);
        });
    }
}

export default new PicoUtils(configJson);