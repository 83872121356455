import React, { Component } from 'react';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';
import PageNotFound from '../common/lib/PageNotFound';
import { MultiLang } from '../config';

interface Props extends RouteComponentProps {
    lang: MultiLang;
    name: string;
}

class PicoXoopsPathRedirect extends Component<Props> {

    getRedirectUrl(): string {
        const { name, location } = this.props;
        const pathname = location.pathname || '';
        const search = new RegExp(`^/modules/${name}(?:/+(.*))?$`);
        const matches = pathname.match(search);
        if (matches === null) {
            return '';
        }
        const path = matches[1] || '';
        switch (path) {
            case '':
            case 'index.php': {
                const params = new URLSearchParams(location.search);
                const paramString = params.toString();
                return '/' + name + '/' + (paramString !== '' ? '?' + paramString : '');
            }
        }
        return '/' + name + '/' + path;
    }

    render() {
        const { lang } = this.props;
        const url = this.getRedirectUrl();
        if (url === '') {
            return <PageNotFound lang={lang} />;
        }
        return <Redirect to={url} />;
    }
}

export default withRouter(PicoXoopsPathRedirect);
