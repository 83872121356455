import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { MultiLang } from '../../config';
import Functions from '../../functions';
import imageStar from '../../database/assets/images/star.gif';
import rankings from '../../database/assets/rankings.json';
import ItemUtil, { ItemCore } from '../../database/lib/ItemUtil';
import VisiomePreviewList from './VisiomePreviewList';

interface Props {
    lang: MultiLang;
}

const orderLabel = (order: number, lang: string) => {
    return String(order) + (lang === 'en' ? Functions.ordinal(order) : '位')
}

const dummyItemIds = [5979, 6291, 6106, 5953, 5998, 6448, 6163, 6434, 6181, 6069];

const VisiomeTop10Items = (props: Props) => {
    const { lang } = props;
    const itemIds: number[] = [];
    const items = [
        {
            title: Functions.mlang('[en]Frequently accessed items[/en][ja]頻繁に閲覧されるアイテム[/ja]', lang),
            list: rankings.accessed.map((item, idx) => {
                const url = ItemUtil.getUrl(item as ItemCore);
                const title = Functions.mlang(item.title, lang);
                const order = orderLabel(idx + 1, lang);
                itemIds.push(item.item_id);
                return (
                    <div key={idx}>
                        {order} <Link to={url} title={title}>{title}</Link>
                        {idx === 0 && <img style={{ marginLeft: '3px', verticalAlign: 'middle' }} src={imageStar} alt={order} />}
                    </div>
                );
            }),
        },
        {
            title: Functions.mlang('[en]Frequently downloaded items[/en][ja]頻繁にダウンロードされるアイテム[/ja]', lang),
            list: rankings.downloaded.map((item, idx) => {
                const url = ItemUtil.getUrl(item as ItemCore);
                const title = Functions.mlang(item.title, lang);
                const order = orderLabel(idx + 1, lang);
                itemIds.push(item.item_id);
                return (
                    <div key={idx}>
                        {order} <Link to={url} title={title}>{title}</Link>
                        {idx === 0 && <img style={{ marginLeft: '3px', verticalAlign: 'middle' }} src={imageStar} alt={order} />}
                    </div>
                );
            }),
        },
    ];
    return (
        <div>
            <VisiomePreviewList lang={lang} number={10} itemIds={itemIds.concat(dummyItemIds)} />
            {items.map((item, idx) => {
                return (
                    <Fragment key={idx}>
                        {idx !== 0 && <hr />}
                        <span>{item.title}</span>
                        <div>{item.list}</div>
                    </Fragment>
                );
            })}
        </div>
    );
}

export default VisiomeTop10Items;