import moment from 'moment';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import PageNotFound from '../common/lib/PageNotFound';
import XoopsCode from '../common/lib/XoopsCode';
import { MultiLang } from '../config';
import Functions from '../functions';
import CreditsUtils from './lib/CreditsUtils';
import NoticeSiteHasBeenArchived from '../common/lib/NoticeSiteHasBeenArchived';
import { Helmet } from 'react-helmet';

interface Params {
    id: string;
}

interface Props extends RouteComponentProps<Params> {
    lang: MultiLang;
}

const CreditsPage = (props: Props) => {
    const { lang } = props;
    const params = props.match.params;
    if (params.id === '') {
        return <PageNotFound lang={lang} />;
    }
    const pageId = parseInt(props.match.params.id, 10);
    const page = CreditsUtils.getPage(pageId);
    if (page === null) {
        return <PageNotFound lang={lang} />;
    }
    const title = Functions.mlang(page.title, lang);
    return (
        <>
            <Helmet>
                <title>{title} - {Functions.siteTitle(lang)}</title>
            </Helmet>
            <h3>{title}</h3>
            <NoticeSiteHasBeenArchived lang={lang} />
            <div style={{ textAlign: 'right' }}>{Functions.mlang('[en]Last Update[/en][ja]最終更新日[/ja]', lang)} : {moment(new Date(page.lastupdate * 1000)).format('MMMM Do, YYYY')}</div>
            <hr />
            <XoopsCode lang={lang} text={page.content} dohtml={true} />
        </>
    );
}

export default CreditsPage;