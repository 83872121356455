const SITE_TITLE = 'Visiome Platform';
const SITE_SLOGAN = 'Digital archive for vision science';
const GOOGLE_ANALYTICS_TRACKING_ID = 'UA-2787276-1';
const XOONIPS_ITEMTYPES = ['binder', 'model', 'data', 'stimulus', 'tool', 'presentation', 'paper', 'book', 'url'];
const PICO_MODULES = ['manual_ja', 'primface'];

export type MultiLang = 'en' | 'ja';

const Config = {
    SITE_TITLE,
    SITE_SLOGAN,
    GOOGLE_ANALYTICS_TRACKING_ID,
    XOONIPS_ITEMTYPES,
    PICO_MODULES,
}

export default Config;
