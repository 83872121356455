import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { MultiLang } from '../../config';
import Functions from '../../functions';
import imageStar from '../../database/assets/images/star.gif';
import contents from '../../database/assets/recent-contents.json';
import ItemUtil, { ItemCore } from '../../database/lib/ItemUtil';
import VisiomePreviewList from './VisiomePreviewList';

interface Props {
    lang: MultiLang;
}

const dummyItemIds = [6412, 6975, 6748, 6448, 6735, 6894, 6981, 6722, 6721, 6177];

const VisiomeNewItems = (props: Props) => {
    const { lang } = props;
    const list = contents.map((item, idx) => {
        const url = ItemUtil.getUrl(item as ItemCore);
        const title = Functions.mlang(item.title, lang);
        const date = moment(new Date(item.last_update_date * 1000)).format('Y/M/D');
        const order = String(idx + 1) + (lang === 'en' ? Functions.ordinal(idx + 1) : '位')
        return (
            <div key={item.item_id}>
                {date} <Link to={url} title={title}>{title}</Link>
                {idx === 0 && <img style={{ marginLeft: '3px', verticalAlign: 'middle' }} src={imageStar} alt={order} />}
            </div>
        );
    });
    const itemIds = contents.map((itemCore: ItemCore) => { return itemCore.item_id; });
    return (
        <div>
            <VisiomePreviewList lang={lang} number={10} itemIds={itemIds.concat(dummyItemIds)} />
            <div>{list}</div>
        </div >
    );
}

export default VisiomeNewItems;