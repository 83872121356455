import React from 'react';
import { Link } from 'react-router-dom';
import { MultiLang } from '../../config';
import Functions from '../../functions';
import CreditsUtils from '../lib/CreditsUtils';

interface Props {
    lang: MultiLang;
}

const CreditsMenu = (props: Props) => {
    const { lang } = props;
    const menu = CreditsUtils.getMenu();
    const links = menu.map((item, idx) => {
        const title = Functions.mlang(item.title, lang);
        const style = idx === 0 ? 'menuTop' : 'menuMain';
        return <li key={idx}><Link className={style} to={item.link}>{title}</Link></li>
    });
    return (
        <ul className="mainmenu">
            {links}
        </ul>
    );
}

export default CreditsMenu;
