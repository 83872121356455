import Tree from 'rc-tree';
import { DataNode, EventDataNode } from 'rc-tree/lib/interface';
import React, { Component, ReactText } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { MultiLang } from '../../config';
import Functions from '../../functions';
import IndexUtil, { Index, INDEX_ID_PUBLIC } from '../lib/IndexUtil';
import styles from './IndexTree.module.css';

interface Props extends RouteComponentProps {
    lang: MultiLang;
}

interface State {
    tree: DataNode[];
    expandableKeys: string[];
    expandedKeys: string[];
    selectedKeys: number[];
}

class IndexTree extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.handleClickOpenAll = this.handleClickOpenAll.bind(this);
        this.handleClickCloseAll = this.handleClickCloseAll.bind(this);
        this.handleExpand = this.handleExpand.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        const res = this.load();
        this.state = {
            tree: res.elements,
            expandableKeys: res.keys,
            expandedKeys: res.expandedKeys,
            selectedKeys: [],
        };
    }

    load() {
        const { lang } = this.props;
        let keys: string[] = [];
        let eKeys: string[] = [];
        const makeTreeNode = (index: Index, depth: number): DataNode => {
            const title = Functions.mlang(index.title, lang) + (index.numOfItems > 0 ? ' (' + index.numOfItems + ')' : '');
            const children = IndexUtil.getChildren(index.id);
            if (children.length === 0) {
                return { key: String(index.id), title: title };
            }
            const childTreeNodes = children.map((value: Index) => {
                return makeTreeNode(value, depth + 1);
            });
            if (depth < 1) {
                eKeys.push(String(index.id));
            }
            keys.push(String(index.id));
            return { key: String(index.id), title: title, children: childTreeNodes };
        };
        let elements: DataNode[] = [];
        const index = IndexUtil.get(INDEX_ID_PUBLIC);
        if (index !== null) {
            elements.push(makeTreeNode(index, 0));
        }
        return {
            elements: elements,
            keys: keys,
            expandedKeys: eKeys,
        }
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        const { lang } = this.props;
        if (prevProps.lang !== lang) {
            const res = this.load();
            this.setState({
                tree: res.elements,
                selectedKeys: [],
            });
        }
    }

    handleClickOpenAll() {
        this.setState({ expandedKeys: this.state.expandableKeys })
    }

    handleClickCloseAll() {
        this.setState({ expandedKeys: [] })
    }

    handleExpand(expandedKeys: ReactText[], info: {
        node: EventDataNode;
        expanded: boolean;
        nativeEvent: MouseEvent;
    }): void {
        const keys: string[] = expandedKeys.map((key) => {
            return typeof key === 'string' ? key : String(key);
        });
        this.setState({ expandedKeys: keys });
    }

    handleSelect(selectedKeys: ReactText[], info: {
        event: 'select';
        selected: boolean;
        node: EventDataNode;
        selectedNodes: DataNode[];
        nativeEvent: MouseEvent;
    }): void {
        const selectedKey = selectedKeys.shift() || 0;
        const key = typeof selectedKey === 'string' ? parseInt(selectedKey, 10) : selectedKey;
        const url = IndexUtil.getUrl(key);
        this.props.history.push(url);
        this.setState({ selectedKeys: [] });
    }

    render() {
        return (
            <div className={styles.container}>
                <button className={styles.formButton} onClick={this.handleClickOpenAll}>open all</button>
                <button className={styles.formButton} onClick={this.handleClickCloseAll}>close all</button>
                <Tree className={styles.indexTree} expandedKeys={this.state.expandedKeys} selectedKeys={this.state.selectedKeys} onExpand={this.handleExpand} onSelect={this.handleSelect} showIcon={false} treeData={this.state.tree} />
            </div>
        );
    }
}

export default withRouter(IndexTree);
