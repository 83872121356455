import React from 'react';
import bannerJapanNode from '../../common/assets/theme/images/banner_incf-japan-node.gif';
import bannerNIF from '../../common/assets/theme/images/banner_nif.gif';
import bannerCBS from '../../common/assets/theme/images/banner_riken-cbs.gif';
import bannerXooNIps from '../../common/assets/theme/images/banner_xoonips.gif';
import LinkImage from "../../common/lib/LinkImage";
import { MultiLang } from "../../config";
import Functions from "../../functions";

interface Props {
    lang: MultiLang;
}

const VisiomeLinks = (props: Props) => {
    const { lang } = props;
    const iconJapanNode = <LinkImage image={bannerJapanNode} title={Functions.mlang('[en]INCF Japan Node[/en][ja]INCF 日本ノード[/ja]', lang)} url={'https://www.neuroinf.jp/'} />;
    const iconXooNIps = <LinkImage image={bannerXooNIps} title={Functions.mlang('[en]The XooNIps Project[/en][ja]XooNIps 公式サイト[/ja]', lang)} url={'https://xoonips.osdn.jp/'} />;
    const iconCBS = <LinkImage image={bannerCBS} title={Functions.mlang('[en]RIKEN Center for Brain Science[/en][ja]理化学研究所 脳神経科学研究センター[/ja]', lang)} url={'https://cbs.riken.jp/'} />;
    const iconNIF = <LinkImage image={bannerNIF} title={Functions.mlang('NIF: Neuroscience Information Framework', lang)} url={'https://www.neuinfo.org/'} />;
    return (
        <div style={{ fontSize: '10pt' }}>
            This site is a part of {iconJapanNode}, and is powered by {iconXooNIps}.
            The server for this site is located in {iconCBS}.
            {iconNIF}
        </div>
    );
}

export default VisiomeLinks;