import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { MultiLang } from '../../config';
import Functions from '../../functions';
import ItemUtil, { Item } from '../../database/lib/ItemUtil';

interface Props {
    lang: MultiLang;
    number: number;
    itemIds: number[];
}

interface State {
    previews: Item[];
}

const findPreview = (item: Item) => {
    return item.file.find((file) => {
        return file.file_type_name === 'preview';
    });
};


class VisiomePreviewList extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            previews: [],
        }
    }

    componentDidMount() {
        const { number, itemIds } = this.props;
        const uniqItemIds = itemIds.filter((itemId, idx, self) => { return self.indexOf(itemId) === idx; });
        ItemUtil.getList(uniqItemIds, (results) => {
            const items: Item[] = [];
            results.data.forEach((item) => {
                if (items.length >= number) {
                    return;
                }
                const preview = findPreview(item);
                if (typeof preview !== 'undefined') {
                    items.push(item);
                }
            });
            this.setState({ previews: items });
        });
    }

    render() {
        const { lang } = this.props;
        const previews: JSX.Element[] = [];
        this.state.previews.forEach((item) => {
            const file = findPreview(item);
            if (typeof file === 'undefined') {
                return;
            }
            const url = ItemUtil.getUrl(item);
            const title = Functions.mlang(item.title, lang);
            const image = ItemUtil.getPreviewFileUrl(file);
            previews.push(
                <div key={item.item_id} style={{ float: 'left', textAlign: 'center', margin: '5px 15px', width: '100px', height: '100px' }}>
                    <Link to={url}><img src={image} alt={title} title={title} /></Link>
                </div>
            );
        });
        return <div className="clearfix" style={{ width: '680px', margin: '0 auto' }}>{previews}</div>;
    }
}

export default VisiomePreviewList;