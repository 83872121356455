import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { MultiLang } from '../config';
import VisiomeLinks from '../custom/blocks/VisiomeLinks';
import VisiomeNewItems from '../custom/blocks/VisiomeNewItems';
import VisiomePreviewList from '../custom/blocks/VisiomePreviewList';
import VisiomeTop10Items from '../custom/blocks/VisiomeTop10Items';
import Announce from '../custom/blocks/Welcome';
import Footer from './Footer';
import MainContent from './MainContent';

interface Props {
    lang: MultiLang;
}

const CenterBlocks = (props: Props) => {
    const { lang } = props;
    return (
        <div className="block">
            <div className="clearfix">
                <td className="centerLcolumn">
                </td>
                <td className="centerRcolumn">
                </td>
            </div>
            <div className="centerCcolumn">
                <div className="block">
                    <div className="blockTitle">Welcome to Visiome Platform !</div>
                    <div className="blockContent">
                        <Announce lang={lang} />
                    </div>
                </div>
                <div className="block">
                    <div className="blockTitle">New Items</div>
                    <div className="blockContent">
                        <VisiomeNewItems lang={lang} />
                    </div>
                </div>
                <div className="block">
                    <div className="blockTitle">Top 10 Items</div>
                    <div className="blockContent">
                        <VisiomeTop10Items lang={lang} />
                    </div>
                </div>
                <div className="block">
                    <div className="blockTitle">Recommendations</div>
                    <div className="blockContent">
                        <VisiomePreviewList lang={lang} number={5} itemIds={[6894, 3315, 5915, 6434, 6105]} />
                    </div>
                </div>
                <div className="block">
                    <div className="blockTitle">Links</div>
                    <div className="blockContent">
                        <VisiomeLinks lang={lang} />
                    </div>
                </div>
            </div>
        </div>
    );
}

const CenterColumn = (props: Props) => {
    const { lang } = props;
    return (
        <div className="centercolumn">
            <Switch>
                <Route exact path="/" render={() => <CenterBlocks lang={lang} />} />
            </Switch>
            <div className="block">
                <MainContent lang={lang} />
            </div>
            <div className="block">
                <Footer lang={lang} />
            </div>
        </div>
    );
}

export default CenterColumn;