import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import AppRoot from './common/AppRoot';
import './App.css';

class App extends Component {

    render() {
        return (
            <CookiesProvider>
                <BrowserRouter>
                    <AppRoot />
                </BrowserRouter >
            </CookiesProvider>
        );
    }
}

export default App;
