import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import PageNotFound from '../common/lib/PageNotFound';
import { MultiLang } from '../config';
import Functions from '../functions';
import PicoUtils, { PicoCategoryData, PicoContentData } from './lib/PicoUtils';

interface Props {
    lang: MultiLang;
    name: string;
}

interface PicoMenuCategoryProps {
    lang: MultiLang;
    name: string;
    category: PicoCategoryData;
    depth: number;
}

const PicoMenuCategory = (props: PicoMenuCategoryProps) => {
    const { lang, name, category, depth } = props;
    const subCategories = PicoUtils.getSubCategories(name, category.id);
    const contents = PicoUtils.getCategoryContents(name, category.id);
    const level = 'level' + depth;
    const link = <Link to={'/' + name + '/' + category.link}>{Functions.mlang(category.title, lang)}</Link>;
    let title = <h6>{link}</h6>;
    switch (depth) {
        case 1:
            title = <h1>{link}</h1>;
            break;
        case 2:
            title = <h2>{link}</h2>;
            break;
        case 3:
            title = <h3>{link}</h3>;
            break;
        case 4:
            title = <h4>{link}</h4>;
            break;
        case 5:
            title = <h5>{link}</h5>;
            break;
    }
    return (
        <>
            {title}
            {contents.length > 0 && (
                <div className={level}>
                    <ul className="pico_list_contents_in_menu">
                        {contents.map((content: PicoContentData) => {
                            const url = '/' + name + '/' + content.link;
                            return <li key={content.id}><Link to={url}>{Functions.mlang(content.title, lang)}</Link></li>;
                        })}
                    </ul>
                </div>
            )}
            {subCategories.length > 0 && (
                <>
                    {subCategories.map((subCategory: PicoCategoryData) => {
                        return <PicoMenuCategory key={subCategory.id} lang={lang} name={name} category={subCategory} depth={depth + 1} />;
                    })}
                </>
            )}
        </>
    );
}

const PicoMenu = (props: Props) => {
    const { lang, name } = props;
    const pico = PicoUtils.getModule(name);
    if (pico === null) {
        return <PageNotFound lang={lang} />;
    }
    const category = PicoUtils.getCategory(name, 0);
    if (category === null) {
        return <PageNotFound lang={lang} />;
    }
    return (
        <div className="pico_container pico_menu">
            <Helmet>
                <title>{Functions.mlang('[en]Menu[/en][ja]メニュー[/ja]', lang)} - {Functions.mlang(pico.name, lang)} - {Functions.siteTitle(lang)}</title>
            </Helmet>
            {pico.message !== '' && (
                <p>{Functions.mlang(pico.message, lang)}</p>
            )}
            <PicoMenuCategory lang={lang} name={name} category={category} depth={1} />
        </div>
    );
};

export default PicoMenu;