import React, { Component } from 'react';
import { Link } from 'react-router-dom';

interface Props {
    url: string;
    title: string;
    image: string;
    imageHover?: string;
}

interface State {
    image: string;
}

class LinkImage extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            image: props.image,
        };
        this.handleMouseOver = this.handleMouseOver.bind(this);
        this.handleMouseOut = this.handleMouseOut.bind(this);
    }

    handleMouseOver() {
        const { imageHover } = this.props;
        if (typeof imageHover !== 'undefined') {
            this.setState({ image: imageHover })
        }
    }

    handleMouseOut() {
        const { image: imageNormal, imageHover } = this.props;
        if (typeof imageHover !== 'undefined') {
            this.setState({ image: imageNormal })
        }
    }

    render() {
        const { url, title } = this.props;
        const image = <img style={{ verticalAlign: 'middle' }} src={this.state.image} alt={title} title={title} />;
        if (url.match(/^(\/|\.)/) === null) {
            return (
                <a href={url} target="_blank" rel="noopener noreferrer" onMouseOver={this.handleMouseOver} onMouseOut={this.handleMouseOut}>
                    {image}
                </a>
            );
        }
        return (
            <Link to={url} onMouseOver={this.handleMouseOver} onMouseOut={this.handleMouseOut}>
                {image}
            </Link>
        );
    }
}

export default LinkImage;
